<template>
  <div style="width: 100%;">
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="title" label="名称" width="200"></el-table-column>
        <el-table-column prop="command" label="口令"></el-table-column>
        <el-table-column prop="total_price" label="总额"></el-table-column>
        <el-table-column prop="grant_price" label="已领金额"></el-table-column>
        <el-table-column prop="wait_price" label="未领金额"></el-table-column>
        <el-table-column prop="total_num" label="数量"></el-table-column>
        <el-table-column prop="rate_fee" label="手续费"></el-table-column>
        <el-table-column prop="pay_fee" label="支付费用"></el-table-column>
        <el-table-column :formatter="statusFormatter" label="状态"></el-table-column>
        <el-table-column label="创建时间">
          <template #default="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template #default="s">
            <el-button v-if="s.row.status == 2" @click="backend(s.row)" size="small">退回剩余金额</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "list",
  components: {},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      loading: false,
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    backend(row){
      this.$api.plugin.voiceRedPacket.backend({id:row.id}).then(()=>{
        this.$message.success("操作成功");
        this.loadList();
      })
    },
    statusFormatter(item) {
      let str = "";
      switch (parseInt(item.status)) {
        case 0:
          str = "未支付";
          break;
        case 1:
          str = "已支付";
          break
        case 2:
          str = "已结束未领完";
          break;
        case 3:
          str = "已领完";
          break;
        case 4:
          str = "已退回剩余";
          break;
      }
      return str;
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      this.$api.plugin.voiceRedPacket.list({
        page: this.page,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>